
export default function formatDecWithoutComa(money) {
  if (money > 0) {
    return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else  {
    return (money == null ? "" : money.toString()).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
  }
}


