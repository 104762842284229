<template>
    <v-container>
      <h4 class="mb-5">{{ $t("message.label-order-list") }}</h4>

        <!-- <FilterSales /> -->
    <!-- <FilterSalesOrders class="mb-5"/> -->
    <FormFilterSales class="mb-5" :type="'orders'" />

    <v-data-table
      :headers="headers"
      :items="dataSalesOrderList"
      class="elevation-0"
      hide-default-header
      items-per-page-all-text
      :hide-default-footer="true"
      disable-pagination
    >
      <template v-slot:header="{ props }" style="min-height: 50px; overflow: auto">
        <th class="custom-th text-center">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 120px">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 120px">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 250px">
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[5].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[6].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[7].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[8].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[9].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 250px">
          {{ props.headers[10].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 200px">
          {{ props.headers[11].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 200px">
          {{ props.headers[12].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[13].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 170px">
          {{ props.headers[14].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[15].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[16].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[17].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[18].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 240px">
          {{ props.headers[19].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 240px">
          {{ props.headers[20].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[21].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 250px">
          {{ props.headers[22].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 200px">
          {{ props.headers[23].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 240px">
          {{ props.headers[24].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 240px">
          {{ props.headers[25].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 240px">
          {{ props.headers[26].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 240px">
          {{ props.headers[27].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 240px">
          {{ props.headers[28].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 270px">
          {{ props.headers[29].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 200px">
          {{ props.headers[30].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[31].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[32].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[32].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 150px">
          {{ props.headers[33].text }}
        </th>
      </template>

      <!-- REDIRECT TO ORDER DETAIL -->
      <template v-slot:[`item.order_no`]="{ item }">
        <a href="" @click.prevent="toOrderDetail(item.id)">{{ item.order_no }}</a>
      </template>

      <!-- REDIRECT TO CUSTOMER DETAIL -->
      <template v-slot:[`item.customer_name`]="{ item }"> 
        <a href="" @click.prevent="toCustomerDetail(item)">{{ item.customer_name }}</a>
      </template>
    </v-data-table>

    </v-container>
</template>

<script>
// import FilterSales from '../components/FilterSales.vue'
import formatDecWithoutComa from '../helpers/formatDecWithoutComa'
import FilterSalesOrders from '../components/FilterSalesOrders.vue';
import FormFilterSales from '../components/FormFilterSales.vue'
export default {
    name: "SalesOrders",
    components: {
        // FilterSales
        FilterSalesOrders,
        FormFilterSales
    },
    data() {
        return {

        }
    },
    methods: {
      toOrderDetail(order_id) {
        this.$router.push(`/order/${order_id}`)
      },
      toCustomerDetail(order) {
        this.$router.push(`/customer/detail/${order.customer_id}`)
      },
      async dispatchSalesOrderList() {
        return await this.$store.dispatch("sales_module/fetchSalesOrders")
      }
    },
    computed: {
        dataSalesOrderList() {
          let dataSales = this.$store.state.sales_module.salesOrderList;

          if(dataSales) {
            dataSales.map((sales) => {
              sales.total_amount_w_delivery_fee_per_store_actual = formatDecWithoutComa(sales.total_amount_w_delivery_fee_per_store_actual)
              sales.refundable_amount = formatDecWithoutComa(sales.refundable_amount)
              sales.total_amount_w_delivery_fee_per_store = formatDecWithoutComa(sales.total_amount_w_delivery_fee_per_store)
              sales.sales_amount = formatDecWithoutComa(sales.sales_amount)
              sales.stripe_fee = formatDecWithoutComa(sales.stripe_fee)
              sales.balance_amount = formatDecWithoutComa(sales.balance_amount)
              sales.payout_amount = formatDecWithoutComa(sales.payout_amount)
              sales.subtotal_exclude_taxable_value_8 = formatDecWithoutComa(sales.subtotal_exclude_taxable_value_8)
              sales.subtotal_consumption_tax_8 = formatDecWithoutComa(sales.subtotal_consumption_tax_8)
              sales.subtotal_exclude_taxable_value_10 = formatDecWithoutComa(sales.subtotal_exclude_taxable_value_10)
              sales.subtotal_consumption_tax_10 = formatDecWithoutComa(sales.subtotal_consumption_tax_10)
              sales.subtotal_include_taxable_value_8 = formatDecWithoutComa(sales.subtotal_include_taxable_value_8)
              sales.subtotal_consumption_taxable_8 = formatDecWithoutComa(sales.subtotal_consumption_taxable_8)
              sales.subtotal_include_taxable_value_10 = formatDecWithoutComa(sales.subtotal_include_taxable_value_10)
              sales.subtotal_consumption_taxable_10 = formatDecWithoutComa(sales.subtotal_consumption_taxable_10)
              sales.subtotal_non_taxable_value = formatDecWithoutComa(sales.subtotal_non_taxable_value)
              sales.subtotal = formatDecWithoutComa(sales.subtotal)
              sales.subtotal_tax_amount = formatDecWithoutComa(sales.subtotal_tax_amount)
            })
          }
          
          return dataSales
        },
        headers() {
            return [
                { text: "ID", align: "center", value: "id" },
                { text: this.$t("message.label-store_name"), value: "store_name", align: "center", },
                { text: this.$t("message.label-customer_name"), value: "customer_name", align: "center",  },
                { text: this.$t("message.label-delivery_destination"), align: "center", value: "delivery_destination" },
                {
                    text: this.$t("message.order-number"),
                    align: "center",
                    value: "order_no"
                },
                {
                text: this.$t("message.label-invoice"),
                value: "invoice_no",
                align:"center",
                },
                {
                    text: this.$t("message.label-total_amount_actual"),
                    align: "center",
                    value: "total_amount_w_delivery_fee_per_store_actual"
                },
                {
                    text: this.$t("message.label-refund"),
                    align: "center",
                    value: "refundable_amount"
                },
                {
                    text: this.$t("message.label-total_amount_delivery_fee_store"),
                    align: "center",
                    value: "total_amount_w_delivery_fee_per_store"
                },
                {
                    text: this.$t("message.label-logistic_price"),
                    align: "center", 
                    value: "logistic_price_actual",
                    
                },
                {
                    text: this.$t("message.label-platform_fee"),
                    align: "center",
                    value: "platform_fee",
                    
                },
                {
                    text: this.$t("message.label-stripe_fee"),
                    align: "center",
                    value: "stripe_fee",
                    
                },
                {
                    text: this.$t("message.label-sales_amount"),
                    align: "center",
                    value: "sales_amount"
                },
                {
                    text: this.$t("message.label-amount_deposit"),
                    align: "center",
                    value: "amount_of_deposit"
                },
                {
                  text: this.$t("message.payment_card_type"),
                  align: "center",
                  value: "payment_card_type"
                },
                {
                  text: this.$t("message.payment_method"),
                  align: "center",
                  value: "payment_method"
                },
                {
                  text: this.$t("message.balance_status"),
                  align: "center",
                  value: "balance_status"
                },
                {
                  text: this.$t("message.balance_amount"),
                  align: "center",
                  value: "balance_amount"
                },
                {
                  text: this.$t("message.payout_amount"),
                  align: "center",
                  value: "payout_amount"
                },
                {
                  text: this.$t("message.payout_arrival_datetime"),
                  align: "center",
                  value: "payout_arrival_datetime"
                },
                {
                  text: this.$t("message.payout_avail_datetime"),
                  align: "center",
                  value: "payout_avail_datetime"
                },
                {
                  text: this.$t("message.payout_status"),
                  align: "center",
                  value: "payout_status"
                },
                {
                    text: this.$t("message.label-subtotal_exclude_taxable_value_8"),
                    align: "center",
                    value: "subtotal_exclude_taxable_value_8"
                },
                {
                    text: this.$t("message.label-subtotal_consumption_tax_8"),
                    align: "center",
                    value: "subtotal_consumption_tax_8"
                },
                {
                    text: this.$t("message.label-subtotal_exclude_taxable_value_10"),
                    align: "center",
                    value: "subtotal_exclude_taxable_value_10"
                },
                {
                    text: this.$t("message.label-subtotal_consumption_tax_10"),
                    align: "center",
                    value: "subtotal_consumption_tax_10"
                },
                {
                    text: this.$t("message.label-subtotal_include_taxable_value_8"),
                    align: "center",
                    value: "subtotal_include_taxable_value_8"
                },
                {
                    text: this.$t("message.label-subtotal_consumption_taxable_8"),
                    align: "center",
                    value: "subtotal_consumption_taxable_8"
                },
                {
                    text: this.$t("message.label-subtotal_include_taxable_value_10"),
                    align: "center",
                    value: "subtotal_include_taxable_value_10"
                },
                {
                    text: this.$t("message.label-subtotal_consumption_taxable_10"),
                    align: "center",
                    value: "subtotal_consumption_taxable_10"
                },
                {
                    text: this.$t("message.label-subtotal_non_taxable_value"),
                    align: "center",
                    value: "subtotal_non_taxable_value"
                },
                {
                    text: this.$t("message.label-subtotal"),
                    align: "center",
                    value: "subtotal"
                },
                {
                    text: this.$t("message.label-subtotal_tax_amount"),
                    align: "center",
                    value: "subtotal_tax_amount"
                },
                {
                text: this.$t("message.order-date"),
                value: "created_at",
                align: "center",
                },
                { text: this.$t("message.label-delivery-date"), value: "delivery_date", align: "center",},
            ]
        }
    },
    created() {
        this.dispatchSalesOrderList()
    }
}
</script>

<style>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
</style>