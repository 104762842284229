<template>
    <div>
        <v-row justify="space-between" align="center" no-gutters>
            <v-col cols="10" md="8">
                <v-row>
                    <v-col cols="12" md="3" xs="4" class="">
                        <span>{{ $t("message.shop") }}</span>
                        <v-autocomplete
                        v-model="filtered.storeId"
                        :items="storeListData"
                        item-text="store_name"
                        item-value="id"
                        outlined
                        class="input-class mt-1"
                        style="font-size: 14px;"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" xs="4">
                        <span>{{ $t("message.order-or-delivery") }}</span>
                        <v-select
                            v-model="filtered.type.value"
                            :items="typeOrderDelivery"
                            item-text="text"
                            item-value="value"
                            class="input-class mt-1"
                            style="font-size: 14px;"
                            outlined
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" xs="6">
                        <span>
                            {{ $t("message.label-filter-request-datetime") }}
                        </span>
                        <v-row no-gutters class="row-amount">
                            <v-col cols="12" md="5">
                                <v-menu
                                v-model="modalDateFrom"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    class="mt-1"
                                    outlined
                                    clearable
                                    v-model="filtered.dateFrom"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    style="font-size: 14px;"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filtered.dateFrom"
                                    @input="modalDateFrom = false"
                                ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col class="minus-amount">
                                <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                            </v-col>

                            <v-col cols="12" md="5">
                                <v-menu
                                v-model="modalDateTo"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    class="mt-1"
                                    outlined
                                    clearable
                                    v-model="filtered.dateTo"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    style="font-size: 14px;"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filtered.dateTo"
                                    @input="modalDateTo = false"
                                ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="3">
                <v-row class="d-flex">
                    <v-col cols="12" md="6" xs="4" style="width: fit-content">
                        <v-btn rounded color="primary" @click.prevent="submitFilter">
                            <v-icon left>
                                mdi-magnify
                            </v-icon>
                            {{ $t("message.reload") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" xs="4" style="width: fit-content">
                        <v-btn rounded color="primary" @click.prevent="exportCsv">
                            <v-icon left>
                                mdi-export-variant
                            </v-icon>
                            {{ $t("message.download") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "FormFilterSales",
    props: ['type'],
    data() {
        return {
            modalDateFrom: false,
            modalDateTo: false,
            modal: false,
            filtered: {
                type: { id: "2", value: "orderdate" },
                // selectStatus: "",
                dateFrom: "",
                dateTo: "",
                storeId: "",
            },
        }
    },
    methods: {
        submitFilter() {
            this.initFilter()
        },
        async initFilter() {
            await this.$store.dispatch("shop_module/fetchShopAll")
            let store = this.$store.state.shop_module.shopAllResult;
            this.filtered.storeId = store[0].id
            if(this.type == 'orderDetails') {
                await this.$store.dispatch("sales_module/fetchSalesOrderDetails", {
                    store_id: this.filtered.storeId !== null ? this.filtered.storeId : "",
                    dateType: this.filtered.type.value,
                    datefrom: this.filtered.dateFrom !== null ? this.filtered.dateFrom : "",
                    dateto: this.filtered.dateTo !== null ? this.filtered.dateTo : "",
                });
            } else {
                await this.$store.dispatch("sales_module/fetchSalesOrders", {
                    store_id: this.filtered.storeId !== null ? this.filtered.storeId : "",
                    dateType: this.filtered.type.value,
                    datefrom: this.filtered.dateFrom !== null ? this.filtered.dateFrom : "",
                    dateto: this.filtered.dateTo !== null ? this.filtered.dateTo : "",
                })
            }
        },
        async exportCsv() {
            if(this.type == "orderDetails") {
                await this.$store.dispatch("sales_module/exportCsv", {
                    storeId: this.filtered.storeId !== null ? this.filtered.storeId : "",
                    dateType: this.filtered.type.value,
                    datefrom: this.filtered.dateFrom !== null ? this.filtered.dateFrom : "",
                    dateto: this.filtered.dateTo !== null ? this.filtered.dateTo : "",
                });
            } else {
                await this.$store.dispatch("sales_module/exportCsvSalesOrders", {
                    storeId: this.filtered.storeId !== null ? this.filtered.storeId : "",
                    dateType: this.filtered.type.value,
                    datefrom: this.filtered.dateFrom !== null ? this.filtered.dateFrom : "",
                    dateto: this.filtered.dateTo !== null ? this.filtered.dateTo : "",
                });
            }
        }
    },
    computed: {
        typeOrderDelivery() {
            return [
                {
                id: "1",
                text: this.$t("message.delivery-date"),
                value: "deliverydate",
                },
                { id: "2", text: this.$t("message.order-date"), value: "orderdate" },
            ];
        },
        storeListData() {
            let storeResult = this.$store.state.shop_module.shopAllResult;
            return storeResult;
        },
    },
    created() {
        this.initFilter()
    }
}
</script>

<style>

</style>